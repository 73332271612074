<template>
  <section>
    <RedirectFilter />
    <Preloader on-top v-if="callingAPI" />
    <RedirectTableVlm />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import RedirectFilter from '@/components/redirect/vlm/RedirectFilterVlm'
import RedirectTableVlm from '@/components/table/RedirectTableVlm'

export default {
  name: 'RedirectListViewVlm',
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    RedirectTableVlm,
    Preloader,
    RedirectFilter
  }
}
</script>
