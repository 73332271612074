<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="redirects"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    :show-delete-button="isEditable"
    :show-edit-button="isEditable"
    store-name="redirectVlm"
    table-css-class="redirect-table-vlm"
  >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="getSiteUrl(item)"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.originalUrl"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.alias"
      />

      <TitleWithSubtitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="getAliasUrl(item)"
        :subtitle-header="tableHeaders[4].subHeader"
        :subtitle-value="getAliasType(item)"
      />
      <TitleWithSubtitleColumn
        :title-header="tableHeaders[5].name"
        :title-value="item.validFrom ? $options.filters.prettyDate(item.validFrom) : ''"
        :subtitle-header="tableHeaders[5].subHeader"
        :subtitle-value="item.reservedUntil ? $options.filters.prettyDate(item.reservedUntil) : ''"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdById"
      />
      <AbstractColumn :header="tableHeaders[7].name">
        <RedirectStatusVlm :redirect="item" />
      </AbstractColumn>
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import AbstractColumn from '@/components/table/columns/AbstractColumn'
import RedirectStatusVlm from '@/components/redirect/vlm/RedirectStatusVlm'
import TitleWithSubtitleColumn from '@/components/table/columns/TitleWithSubtitleColumn'
import RedirectHelperServiceVlm from '@/services/redirect/RedirectHelperServiceVlm'

export default {
  name: 'RedirectTableVlm',
  components: {
    TitleWithSubtitleColumn,
    RedirectStatusVlm,
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable,
    AbstractColumn
  },
  computed: {
    tableHeaders () {
      return [
        { name: this.$t('redirectVlm.id') },
        { name: this.$t('redirectVlm.server') },
        { name: this.$t('redirectVlm.originalUrl') },
        { name: this.$t('redirectVlm.alias') },
        {
          name: this.$t('redirectVlm.aliasUrl'),
          subHeader: this.$t('redirectVlm.type')
        },
        {
          name: this.$t('redirectVlm.validFrom'),
          subHeader: this.$t('redirectVlm.reservedUntil')
        },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        { name: this.$t('redirectVlm.status') },
        { name: this.$t('actions') }
      ]
    },
    redirects () {
      return this.$store.getters['redirectVlm/list']
    },
    totalCount () {
      return this.$store.getters['redirectVlm/totalCount']
    },
    currentPage () {
      return this.$store.getters['redirectVlm/page']
    }
  },
  methods: {
    isEditable (redirect) {
      return !RedirectHelperServiceVlm.isRedirectReadOnly(redirect)
    },
    getRedirects () {
      this.$store.dispatch('redirectVlm/fetch')
    },
    getAliasType (redirect) {
      return RedirectHelperServiceVlm.getAliasType(redirect)
    },
    getAliasUrl (redirect) {
      return `${this.getSiteUrl(redirect)}/${redirect.alias}`
    },
    getSiteUrl (redirect) {
      if (!redirect.siteId) {
        return ''
      }
      const site = this.$store.getters['site/siteById'](redirect.siteId)
      return site.url
    },
    onPageChange (page) {
      this.$store.commit('redirectVlm/setPage', page)
      this.getRedirects()
    }
  },
  mounted () {
    this.getRedirects()
  }
}
</script>
<style lang="scss">
.redirect-table-vlm {
  &__thead,
  &__tr {
    grid-template-columns: rem(40px) repeat(auto-fit, minmax(100px, 1fr));
  }
}
</style>
